import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {t} from '@lingui/macro';
import {Trans} from '@lingui/macro';
import {Button, SALES_CHANNEL_OPTIONS, SalesChannels, useNotification} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {z} from 'zod';

const getSalesChannelsSchema = () =>
  z.object({
    salesChannels: z
      .enum(['pos', 'posMoto', 'eCommerce', 'ecomMoto', 'payByLink'])
      .array()
      .nonempty({message: t`At least one sales channel is required`}),
  });

type SalesChannelsForm = z.infer<ReturnType<typeof getSalesChannelsSchema>>;

type Props = {
  salesChannels: ('pos' | 'posMoto' | 'eCommerce' | 'ecomMoto' | 'payByLink')[];
};

export const SalesChannelsForm = ({salesChannels}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const formMethods = useForm<SalesChannelsForm>({
    resolver: zodResolver(getSalesChannelsSchema()),
    defaultValues: {
      salesChannels: salesChannels,
    },
  });

  const {handleSubmit} = formMethods;
  const trpcContext = trpc.useUtils();

  const updateTenantRegistrationConfiguration =
    trpc.tenant.updateRegistrationConfiguration.useMutation({
      onSuccess: updatedTenant => {
        trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
        showSuccessNotification(t`Sales channels for onboarding merchants have been updated`);
      },
      onError: () => {
        showErrorNotification(
          t`Failed to update sales channels`,
          t`Something went wrong. Please try again later.`
        );
      },
    });

  const onSubmit = useCallback((data: SalesChannelsForm) => {
    updateTenantRegistrationConfiguration.mutate({
      salesChannels: data.salesChannels,
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-4 py-5 sm:px-6">
        <FormProvider {...formMethods}>
          <SalesChannels
            options={Object.keys(SALES_CHANNEL_OPTIONS) || []}
            defaultValues={salesChannels || []}
          />
        </FormProvider>
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={updateTenantRegistrationConfiguration.isLoading}
            isLoading={updateTenantRegistrationConfiguration.isLoading}
          >
            <Trans>Save</Trans>
          </Button>
        </footer>
      </div>
    </form>
  );
};
