import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {InviteMerchant} from '@/pages/customers/invite-merchant';
import {
  MerchantReviewPanel,
  MerchantReviewPanelProps,
} from '@/pages/customers/merchants-list/merchant-review-panel';
import {MerchantViewPanel} from '@/pages/customers/merchants-list/merchant-view-panel';
import {useStore} from '@/store';
import {XCircleIcon} from '@heroicons/react/24/outline';
import {Plural, Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {tailwindDefaultBreakpoints} from '@zentact/tailwind-config/tailwind-default-breakpoints';
import {
  Breadcrumbs,
  Button,
  EntityPicker,
  TableSortValue,
  Typography,
  useNotification,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DeepPartial} from 'react-hook-form';
import {generatePath, useLocation, useNavigate} from 'react-router-dom';
import {useMediaQuery} from 'usehooks-ts';
import {z} from 'zod';
import {AssignMerchantFeeGroupPanel} from './assign-merchant-fee-group-panel';
import {exportToCsvMerchants} from './csv-export';
import {MerchantsList} from './merchants-list';
import {MerchantsStats} from './merchants-stats';
import {OrganizationFormData} from './organization-form/organization-form';
import {UpdateMerchantSettlementDelayDays} from './update-merchant-settlement-delay-days';

const breadcrumbs = () => [
  {name: t`Customers`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  {name: t`Merchants`, href: '#', current: true},
];

const searchParamsSchema = z.object({
  status: z
    .array(z.nativeEnum(MerchantAccountPublicStatus))
    .or(z.string().transform(str => str.split(',').map(s => s as MerchantAccountPublicStatus)))
    .optional(),
  organizationId: z.string().nullable().optional(),
  businessName: z.string().nullable().optional(),
  feeGroupId: z.string().nullable().optional(),
  inviteOrganizationFormOpen: z
    .boolean()
    .or(z.string().transform(value => value === 'true'))
    .nullable()
    .optional(),
});

export const Merchants = () => {
  const {organizationList, tenant, pspMerchantAccountName} = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${tailwindDefaultBreakpoints.lg})`);

  const {typedSearchParams: typedSearchParamsAll, setTypedSearchParams} =
    useTypedSearchParams(searchParamsSchema);

  const {inviteOrganizationFormOpen, ...typedSearchParams} = typedSearchParamsAll;
  const {
    status,
    organizationId,
    businessName,
    feeGroupId: splitConfigurationGroupId,
  } = typedSearchParams;

  const organizationName = useMemo(
    () => organizationId && organizationList.find(org => org.id === organizationId)?.name,
    [organizationId, organizationList]
  );

  const inviteOrganizationFormData: DeepPartial<OrganizationFormData> =
    location?.state?.inviteOrganizationFormData;

  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const organizationsSummary = trpc.organization.getOrganizationsSummary.useQuery(
    {
      ...(organizationId && {organizationId}),
      ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
      pspMerchantAccountName,
    },
    {
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
    }
  );
  const merchantList = trpc.merchantAccount.getMerchantAccountsList.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        ...(status?.length && {status}),
        ...(organizationId && {organizationId}),
        ...(businessName && {businessName}),
        ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
        pspMerchantAccountName,
      },
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
    }
  );

  const {
    data: merchantDetailsRow,
    on: openMerchantDetailsPanel,
    off: closeMerchantDetailsPanel,
  } = useToggleWithData<MerchantAccountsListItem | null>();

  const {
    data: merchantReviewRow,
    on: openMerchantReviewPanel,
    off: closeMerchantReviewPanel,
  } = useToggleWithData<MerchantReviewPanelProps['merchantAccountRow']>();

  const {
    data: updateSettlementDelayDays,
    on: openUpdateSettlementDelayDays,
    off: closeUpdateSettlementDelayDays,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();

  const {
    data: assignMerchantFeeGroupData,
    on: openAssignMerchantFeeGroupPanel,
    off: closeAssignMerchantFeeGroupPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();

  const handleOpenMerchantDetails = useCallback(
    (row: MerchantAccountsListItem) => {
      if (isDesktop && row.merchantAccount?.id) {
        navigate(
          generatePath(RoutePath.MERCHANT_DETAILS, {merchantAccountId: row.merchantAccount.id})
        );
      } else {
        openMerchantDetailsPanel(row);
      }
    },
    [isDesktop, navigate, generatePath]
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [status, organizationId, businessName, splitConfigurationGroupId, sort]);

  const handleSelectOrganization = useCallback(
    (organizationId?: string) => {
      setTypedSearchParams({organizationId});
    },
    [setTypedSearchParams]
  );

  const handleSelectStatus = useCallback(
    (selectedStatus: MerchantAccountPublicStatus[]) => {
      setTypedSearchParams(
        status &&
          status.length === selectedStatus.length &&
          selectedStatus.every(element => status.includes(element))
          ? {status: []}
          : {status: selectedStatus}
      );
    },
    [setTypedSearchParams, status]
  );
  const handleClearFilters = useCallback(() => {
    setTypedSearchParams({});
  }, []);

  const trpcContext = trpc.useUtils();
  const {i18n} = useLingui();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    if (!pspMerchantAccountName) {
      console.error('pspMerchantAccountName is not defined');
      return;
    }
    setCsvLoading(true);
    try {
      const fullMerchantsList = await trpcContext.merchantAccount.getMerchantAccountsList.fetch({
        ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
        where: {
          ...(status?.length && {status}),
          ...(organizationId && {organizationId}),
          ...(businessName && {businessName: businessName}),
          ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
          pspMerchantAccountName,
        },
      });
      exportToCsvMerchants(fullMerchantsList, i18n);
      showSuccessNotification(t`Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, exportToCsvMerchants, sort, typedSearchParams]);

  const filtersAmount =
    (status?.length ?? 0) +
    (businessName ? 1 : 0) +
    (organizationId ? 1 : 0) +
    (splitConfigurationGroupId ? 1 : 0);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="py-2">
        <Typography variant="header-page" className="flex">
          <Trans>Merchants</Trans>
          {organizationName && ` - ${organizationName}`}
        </Typography>
      </div>
      <div className="flex justify-end items-end gap-2 py-4 max-sm:flex-col sm:items-center">
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          {filtersAmount >= 1 && (
            <div className="flex items-center justify-between space-x-2 overflow-hidden rounded-md bg-white px-6 py-5 font-semibold shadow ring-1 ring-gray-300 sm:p-1.5 sm:text-sm">
              <span className="pl-0.5">
                <Trans>
                  {filtersAmount}{' '}
                  <Plural value={filtersAmount} one="filter applied" other="filters applied" />
                </Trans>
              </span>
              <button type="button" className="shrink-0" onClick={handleClearFilters}>
                <XCircleIcon className="w-5 h-5 text-gray-400 hover:text-gray-600" />
              </button>{' '}
            </div>
          )}
          <div className="font-normal shrink-0 max-sm:w-full">
            <EntityPicker
              selected={organizationId || ''}
              onChange={handleSelectOrganization}
              options={organizationList}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!merchantList.data || merchantList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
          {!tenant?.features?.disableMerchantInviteInAdmin && (
            <Button
              type="button"
              variant="primary"
              size="md"
              className="w-fit max-sm:w-full"
              onClick={() => setTypedSearchParams({inviteOrganizationFormOpen: true})}
            >
              <Trans>Invite New</Trans>
            </Button>
          )}
        </div>
      </div>
      <MerchantsStats
        organizationsSummary={organizationsSummary.data}
        isLoading={organizationsSummary.isLoading}
        handleSelectStatus={handleSelectStatus}
        status={status || []}
      />
      <div className="mt-4">
        {/* @ts-ignore */}
        <MerchantsList
          merchantList={merchantList.data}
          refetch={merchantList.refetch}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleOpenMerchantDetails}
          openReviewPanel={openMerchantReviewPanel}
          openUpdateSettlementDelayDays={openUpdateSettlementDelayDays}
          openAssignMerchantFeeGroupPanel={openAssignMerchantFeeGroupPanel}
          isLoading={merchantList.isLoading}
        />
      </div>
      <InviteMerchant
        isOpen={!!inviteOrganizationFormOpen}
        onCancel={() => setTypedSearchParams({inviteOrganizationFormOpen: false})}
        onSuccess={() => {
          setTypedSearchParams({inviteOrganizationFormOpen: false});
          merchantList.refetch();
        }}
        organizationId={organizationId}
        organizationFormDefaultValue={inviteOrganizationFormData}
      />
      <MerchantViewPanel
        isOpen={!!merchantDetailsRow}
        onCancel={closeMerchantDetailsPanel}
        merchantAccountRow={merchantDetailsRow}
      />
      <MerchantReviewPanel
        isOpen={!!merchantReviewRow}
        onCancel={closeMerchantReviewPanel}
        merchantAccountRow={merchantReviewRow}
        onSubmit={() => merchantList.refetch()}
      />
      {updateSettlementDelayDays && (
        <UpdateMerchantSettlementDelayDays
          merchantAccountRow={updateSettlementDelayDays}
          onClose={closeUpdateSettlementDelayDays}
          isOpen={!!openUpdateSettlementDelayDays}
          triggerRefetch={merchantList.refetch}
        />
      )}
      {assignMerchantFeeGroupData && (
        <AssignMerchantFeeGroupPanel
          merchantAccountRow={assignMerchantFeeGroupData}
          onClose={closeAssignMerchantFeeGroupPanel}
          isOpen={!!assignMerchantFeeGroupData}
          triggerRefetch={merchantList.refetch}
        />
      )}
    </div>
  );
};
