import {Switch} from '@headlessui/react';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {useState} from 'react';
import {cn, useNotification} from '../..';
import {IconSpinner} from '../../icons/spinner';

type Props = {
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  disablePortalPayments: boolean;
};

export const PortalPaymentsDisableToggle = ({trpc, disablePortalPayments}: Props) => {
  const i18n = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [isLoading, setLoading] = useState(false);
  const trpcContext = trpc.useUtils();

  const updateCheckoutConfiguration = trpc.tenant.updateCheckoutConfiguration.useMutation({
    onSuccess: updatedTenant => {
      setLoading(false);
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      showSuccessNotification(
        i18n._('Virtual terminal {status}', {
          status: updatedTenant.features?.disablePortalPayments ? 'disabled' : 'enabled',
        })
      );
    },
    onError: (_error, variables) => {
      setLoading(false);
      showErrorNotification(
        i18n._('Failed to {status} virtual terminal', {
          status: variables.disablePortalPayments ? 'disable' : 'enable',
        })
      );
    },
  });

  const onToggleChange = async (disable: boolean) => {
    setLoading(true);
    updateCheckoutConfiguration.mutate({disablePortalPayments: disable});
  };

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={disablePortalPayments}
        onChange={onToggleChange}
        className={cn(
          disablePortalPayments || isLoading ? 'bg-gray-200' : 'bg-primary-500',
          'focus:ring-primary-500 relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
        )}
        disabled={isLoading}
      >
        {isLoading ? (
          <div className={cn('flex items-center justify-center', !disablePortalPayments && 'pl-5')}>
            <IconSpinner />
          </div>
        ) : (
          <span
            aria-hidden="true"
            className={cn(
              disablePortalPayments ? 'translate-x-0' : 'translate-x-5',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        )}
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{i18n._('Virtual Terminal')}</span>
      </Switch.Label>
    </Switch.Group>
  );
};
