import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {OrganizationsRevenueListItem} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  OrganizationPublicStatus,
  TableColumnSize,
  formatAmount,
  formatPercentage,
} from '@zentact/common';
import {TruncatedText} from '@zentact/ui-tailwind';
import {Link} from 'react-router-dom';

const columnsHelper = createColumnHelper<OrganizationsRevenueListItem>();

export const getOrganizationColumns = ({
  locale,
  currency,
  allowSorts,
}: {locale: LocaleCode; currency: CurrencyCode; allowSorts?: boolean}) => [
  columnsHelper.accessor('organizationName', {
    cell: info => (
      <Link
        className="text-sm text-primary-600"
        to={`${RoutePath.CUSTOMERS_MERCHANTS}?organizationId=${info.row.original.organizationId}&status=${OrganizationPublicStatus.ACTIVE}`}
      >
        <TruncatedText text={info.getValue() || '-'} />
      </Link>
    ),
    header: () => <Trans>Organization</Trans>,
  }),
  columnsHelper.accessor('totalPaymentsCount', {
    cell: info => info.getValue() || '-',
    header: () => <Trans>Total Payments</Trans>,
    size: TableColumnSize.XS,
    meta: {
      ...(allowSorts
        ? {
            sort: {
              isSortable: true,
            },
          }
        : {}),
      align: 'right',
    },
  }),
  columnsHelper.accessor('averageVolume', {
    cell: ({row}) => formatAmount(row.original.averageVolume, locale, currency),
    header: () => <Trans>Avg Transaction</Trans>,
    meta: {
      visibleAt: '2xl',
      ...(allowSorts
        ? {
            sort: {
              isSortable: true,
            },
          }
        : {}),
      align: 'right',
    },
    size: TableColumnSize.XS,
  }),
  columnsHelper.accessor('totalVolume', {
    cell: ({row}) => formatAmount(row.original.totalVolume, locale, currency),
    header: () => <Trans>Total Volume</Trans>,
    meta: {
      collapseAt: 'sm',
      ...(allowSorts
        ? {
            sort: {
              isSortable: true,
            },
          }
        : {}),
      align: 'right',
    },
    size: TableColumnSize.XS,
  }),
  columnsHelper.accessor('net', {
    cell: ({row}) => formatAmount(row.original.net, locale, currency),
    header: () => <Trans>Net</Trans>,
    meta: {
      visibleAt: 'lg',
      ...(allowSorts
        ? {
            sort: {
              isSortable: true,
            },
          }
        : {}),
      align: 'right',
      headerTooltip: t`The total amount received by the organization from all transactions completed within the selected period, adjusted for tenant fees and processing costs.`,
    },
    size: TableColumnSize.XXS,
  }),
  columnsHelper.display({
    id: 'collectedFees',
    cell: ({row}) =>
      formatAmount(row.original.totalRevenue + row.original.processingCost, locale, currency),
    header: () => <Trans>Collected Fees</Trans>,
    meta: {
      visibleAt: '2xl',
      ...(allowSorts
        ? {
            sort: {
              isSortable: true,
            },
          }
        : {}),
      align: 'right',
      headerTooltip: t`The total fees accumulated from transactions, including the charges for transaction processing that were remitted to the provider.`,
    },
    size: TableColumnSize.S,
  }),
  columnsHelper.accessor('processingCost', {
    cell: ({row}) => formatAmount(row.original.processingCost, locale, currency),
    header: () => <Trans>Processing Cost</Trans>,
    meta: {
      visibleAt: 'md',
      ...(allowSorts
        ? {
            sort: {
              isSortable: true,
            },
          }
        : {}),
      align: 'right',
      headerTooltip: t`The sum of charges for transaction processing that were remitted to the provider.`,
    },
    size: TableColumnSize.S,
  }),
  columnsHelper.accessor('totalRevenue', {
    cell: ({row}) => formatAmount(row.original.totalRevenue, locale, currency),
    header: () => <Trans>Profit</Trans>,
    meta: {
      visibleAt: 'sm',
      ...(allowSorts
        ? {
            sort: {
              isSortable: true,
            },
          }
        : {}),
      align: 'right',
      headerTooltip: t`The amount accumulated from transactions, excluding the provider processing fee and an amount remitted to the organization.`,
    },
    size: TableColumnSize.XXS,
  }),
  columnsHelper.display({
    id: 'profitPercentage',
    cell: ({row}) => formatPercentage(row.original.totalVolume, row.original.totalRevenue, locale),
    header: () => <Trans>Profit %</Trans>,
    meta: {
      collapseAt: 'xl',
      visibleAt: 'sm',
      align: 'right',
      headerTooltip: t`Profit % represents the profit generated as a percentage of the total payment volume. It is calculated by dividing the total profit by the total volume and multiplying by 100.`,
    },
    size: TableColumnSize.XXS,
  }),
];
