import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {i18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {ErrorCode} from '@zentact/common';
import {isFormattedTrpcError} from '@zentact/common/src/errors/formattedTrpcError';
import {
  Button,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';

const CreateOrganizationFormSchema = () =>
  z.object({
    organizationName: z
      .string()
      .trim()
      .min(1, {message: t`Organization name is required`})
      .max(100),
    referenceId: z
      .string()
      .trim()
      .min(1, {message: t`Reference cannot be blank`})
      .max(50)
      .optional(),
    supportEmail: z
      .string()
      .max(50, t`Support Email address must not exceed 50 characters`)
      .email(t`Support Email is not valid`)
      .optional()
      .or(z.literal('')),
  });

export type CreateOrganizationFormData = z.infer<ReturnType<typeof CreateOrganizationFormSchema>>;

type CreateOrganizationProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

export const CreateOrganization = ({isOpen, onSuccess, onCancel}: CreateOrganizationProps) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {pspMerchantAccountName, refetchOrganizationList} = useStore();

  const form = useForm<CreateOrganizationFormData>({
    resolver: zodResolver(CreateOrganizationFormSchema()),
  });

  const {
    handleSubmit,
    register,
    setError,
    formState: {errors},
  } = form;

  const createOrganizationMutation = trpc.organization.createOrganization.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`New Organization is created`);
      refetchOrganizationList();
      onSuccess();
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      if (errorCode === ErrorCode.ORGANIZATION_ALREADY_EXISTS) {
        setError('organizationName', {
          type: 'manual',
          message: t`Organization with the same name already exists`,
        });

        return;
      }
      onCancel();
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const onSubmit = useCallback(
    async (data: CreateOrganizationFormData) => {
      await createOrganizationMutation.mutateAsync({
        organizationName: data.organizationName,
        supportEmail: data.supportEmail?.length ? data.supportEmail : undefined,
        pspMerchantAccountName,
      });
    },
    [pspMerchantAccountName]
  );

  const onCancelClick = useCallback((_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel();
    form.reset();
  }, []);

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={t`Add Organization`}
      text={t`Once an organization has been created, you can assign new merchants to it`}
      closeHandler={onCancelClick}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button variant="primary" size="lg" className="w-fit" onClick={handleSubmit(onSubmit)}>
              <Trans>Create New</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-3">
          <Label text={t`Name`}>
            <InputText
              {...register('organizationName', {required: true})}
              hasError={Boolean(errors.organizationName)}
            />
            <ValidationError isVisible={Boolean(errors.organizationName)}>
              {errors.organizationName?.message}
            </ValidationError>
          </Label>
          <Label text={t`Support Email (optional)`}>
            <InputText
              {...register('supportEmail', {required: true})}
              hasError={Boolean(errors.supportEmail)}
            />
            <ValidationError isVisible={Boolean(errors.supportEmail)}>
              {errors.supportEmail?.message}
            </ValidationError>
            <p className="mt-1 text-xs leading-5 text-gray-500">
              {i18n._(
                'This is utilized on checkout pages and in emails sent to customers on behalf of the organization.'
              )}
            </p>
          </Label>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
