import {AuthCallback} from '@/pages/auth/callback';

import {
  AwaitingApproval,
  MerchantDetails,
  Merchants,
  Organizations,
  OrganizationsAccountActivityDetails,
  OrganizationsPayouts,
} from '@/pages/customers';
import {DashboardPage} from '@/pages/dashboard';
import {Statements, TenantAccountActivityDetails, TenantPayouts} from '@/pages/finance';
import {LogoutPage} from '@/pages/logout';

import {AdminLayout} from '@/components';
import {useAccessToken} from '@/hooks';
import {Brand} from '@/pages/configure';
import {Advanced} from '@/pages/configure/advanced';
import {ApiKeys} from '@/pages/configure/api-keys';
import {FeeGroupCreatePage} from '@/pages/configure/create-fee-group';
import {FeeGroupDetailsPage} from '@/pages/configure/fee-group-details';
import {FeeGroups} from '@/pages/configure/fee-groups';
import {NotificationEmails} from '@/pages/configure/notification-emails';
import {Webhooks} from '@/pages/configure/webhooks';
import {
  InterchangeReportPage,
  OtherRevenueReportPage,
  ProfitabilityReportPage,
  TenantReports,
} from '@/pages/reports';
import {MerchantChargebacks, MerchantStoppedProcessing} from '@/pages/risk';
import {TerminalsManage} from '@/pages/terminals';
import {Disputes, PaymentDetails, Payments} from '@/pages/transactions';
import {DeclinedPayments} from '@/pages/transactions/DeclinedPayments';
import {ActiveUsers, InvitedUsers} from '@/pages/users';
import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {ErrorCode} from '@zentact/common';
import {Error419, ErrorPage, Loading} from '@zentact/ui-tailwind';
import {Navigate, Route, Routes} from 'react-router-dom';
import {RoutePath} from '../layout/navigation';

export const AppRoutes = () => {
  const {accessToken} = useAccessToken();
  const {isAuthenticated} = useAuth0();
  const {isLoading, isError, tenant} = useStore();

  if (accessToken.isLoading || (isAuthenticated && isLoading)) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route element={<AdminLayout />} errorElement={<ErrorPage logoutUri={tenant?.logoutUri} />}>
        {!isError && (
          <Route>
            <Route path={RoutePath.DASHBOARD} element={<DashboardPage />} />
            <Route path={RoutePath.CUSTOMERS}>
              <Route index element={<Navigate replace to={RoutePath.CUSTOMERS_MERCHANTS} />} />
              <Route index path={RoutePath.CUSTOMERS_MERCHANTS} element={<Merchants />} />
              <Route path={RoutePath.ORGANIZATIONS} element={<Organizations />} />
              {!tenant?.features?.disableAddingMerchantInPortal && (
                <Route
                  path={RoutePath.CUSTOMERS_AWAITING_APPROVAL}
                  element={<AwaitingApproval />}
                />
              )}
              <Route
                path={RoutePath.CUSTOMERS_ACCOUNT_ACTIVITY}
                element={<OrganizationsAccountActivityDetails />}
              />
              <Route path={RoutePath.STATEMENTS} element={<Statements />} />
              <Route path={RoutePath.CUSTOMERS_PAYOUTS} element={<OrganizationsPayouts />} />
              <Route path={RoutePath.MERCHANT_DETAILS} element={<MerchantDetails />} />
            </Route>
            <Route path={RoutePath.CONFIGURE}>
              <Route index element={<Navigate replace to={RoutePath.FEE_GROUPS} />} />
              <Route path={RoutePath.FEE_GROUPS} element={<FeeGroups />} />
              <Route path={RoutePath.FEE_GROUP_CREATE} element={<FeeGroupCreatePage />} />
              <Route path={RoutePath.FEE_GROUP_DETAILS} element={<FeeGroupDetailsPage />} />
              <Route path={RoutePath.BRAND} element={<Brand />} />
              <Route path={RoutePath.ADVANCED} element={<Advanced />} />
              <Route path={RoutePath.WEBHOOKS} element={<Webhooks />} />
              <Route path={RoutePath.API_KEYS} element={<ApiKeys />} />
              <Route path={RoutePath.NOTIFICATION_EMAILS} element={<NotificationEmails />} />
            </Route>
            <Route path={RoutePath.FINANCE}>
              <Route index element={<Navigate replace to={RoutePath.FINANCE_ACCOUNT_ACTIVITY} />} />
              <Route
                path={RoutePath.FINANCE_ACCOUNT_ACTIVITY}
                element={<TenantAccountActivityDetails />}
              />
              <Route path={RoutePath.FINANCE_PAYOUTS} element={<TenantPayouts />} />
            </Route>
            <Route path={RoutePath.REPORTS}>
              <Route path={RoutePath.REPORTS} element={<TenantReports />} />
              <Route path={RoutePath.REPORTS_PROFITABILITY} element={<ProfitabilityReportPage />} />
              {!!tenant?.features?.enableInterchangeReport?.showInterchangeReportPage && (
                <Route path={RoutePath.REPORTS_INTERCHANGE} element={<InterchangeReportPage />} />
              )}
              <Route path={RoutePath.REPORTS_OTHER_REVENUE} element={<OtherRevenueReportPage />} />
            </Route>
            <Route path={RoutePath.RISK}>
              <Route
                index
                element={<Navigate replace to={RoutePath.RISK_MERCHANT_STOPPED_PROCESSING} />}
              />
              <Route
                path={RoutePath.RISK_MERCHANT_STOPPED_PROCESSING}
                element={<MerchantStoppedProcessing />}
              />
              <Route
                path={RoutePath.RISK_MERCHANT_CHARGREBACKS}
                element={<MerchantChargebacks />}
              />
            </Route>
            {tenant?.features?.enableTerminals && (
              <Route path={RoutePath.TERMINALS}>
                <Route index element={<Navigate replace to={RoutePath.TERMINALS_MANAGE} />} />
                <Route path={RoutePath.TERMINALS_MANAGE} element={<TerminalsManage />} />
              </Route>
            )}
            <Route path={RoutePath.TRANSACTIONS}>
              <Route index element={<Navigate replace to={RoutePath.PAYMENTS} />} />
              <Route path={RoutePath.PAYMENTS} element={<Payments />} />
              <Route path={RoutePath.DECLINED_PAYMENTS} element={<DeclinedPayments />} />
              <Route path={RoutePath.PAYMENT_DETAILS} element={<PaymentDetails />} />
              <Route path={RoutePath.DISPUTES} element={<Disputes />} />
            </Route>
            <Route path="/" element={<Navigate replace to={RoutePath.DASHBOARD} />} />
            <Route path={RoutePath.USER_PROFILE} element={<div>User Profile</div>} />
            <Route path={RoutePath.USERS}>
              <Route index element={<Navigate replace to={RoutePath.ACTIVE_USERS} />} />
              <Route path={RoutePath.ACTIVE_USERS} element={<ActiveUsers />} />
              <Route path={RoutePath.INVITED_USERS} element={<InvitedUsers />} />
            </Route>
          </Route>
        )}
        <Route
          path="*"
          element={
            <Navigate replace to={RoutePath.ERROR} state={{errorCode: ErrorCode.PAGE_NOT_FOUND}} />
          }
        />
      </Route>
      <Route path={RoutePath.ERROR} element={<ErrorPage logoutUri={tenant?.logoutUri} />} />
      <Route path={RoutePath.SESSION_EXPIRED} element={<Error419 />} />
      <Route path={RoutePath.AUTH_CALLBACK} element={<AuthCallback />} />
      <Route path={RoutePath.USER_LOGOUT} element={<LogoutPage />} />
    </Routes>
  );
};
