import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/24/solid';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {OrganizationListItem} from '@zentact/api/src/trpc/routers/organizationRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  TableListSort,
  TableSortValue,
  Typography,
  useDrawerState,
  useNotification,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {OrganizationFilters} from '@zentact/ui-tailwind/elements/OrganizationFilters';
import {useCallback, useEffect, useState} from 'react';
import {z} from 'zod';
import {CreateOrganization} from './create-organization';
import {exportToCsvOrganizations} from './csv-export';
import {OrganizationViewPanel} from './organizations-list';
import {OrganizationsList} from './organizations-list/organizations-list';

const breadcrumbs = () => [
  {name: t`Customers`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  {name: t`Organizations`, href: '#', current: true},
];

const searchParamsSchema = z.object({
  name: z.string().optional(),
  status: z
    .array(z.string())
    .or(z.string().transform(str => str.split(',').map(s => s as MerchantAccountPublicStatus)))
    .optional(),
});

export const Organizations = () => {
  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);
  const {pspMerchantAccountName} = useStore();

  const {status, name} = typedSearchParams;

  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const organizationList = trpc.organization.getOrganizationList.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        ...(name && {name}),
        ...(status?.length && {status}),
        ...(pspMerchantAccountName && {pspMerchantAccountName}),
      },
    },
    {refetchOnWindowFocus: true, refetchInterval: 5000, keepPreviousData: true}
  );

  const {
    isOpen: isCreateOrganizationVisible,
    open: openCreateOrganizationForm,
    close: closeCreateOrganizationForm,
    onSuccess: onCreateOrganizationSuccess,
  } = useDrawerState({
    onSuccessHandler: () => {
      organizationList.refetch();
    },
  });

  const {
    isOpen: isOrganizationDetailsOpen,
    data: organizationViewRow,
    on: openOrganizationPanel,
    off: closeOrganizationPanel,
  } = useToggleWithData<OrganizationListItem>();

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const trpcContext = trpc.useUtils();
  const {i18n} = useLingui();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const organizationList = await trpcContext.organization.getOrganizationList.fetch({
        ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
        where: {
          ...(status?.length && {status}),
          ...(name && {name}),
          ...(pspMerchantAccountName && {pspMerchantAccountName}),
        },
      });
      exportToCsvOrganizations(organizationList, i18n);
      showSuccessNotification(t`Organizations .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Organizations .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [
    setCsvLoading,
    trpcContext,
    exportToCsvOrganizations,
    sort,
    typedSearchParams,
    pspMerchantAccountName,
  ]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Organizations</Trans>
        </Typography>
        <div className="sm:hidden">
          <ResetTableFiltersButton
            defaultFilters={{}}
            activeFilters={typedSearchParams}
            setFilters={setTypedSearchParams}
          />
        </div>
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!organizationList.data || organizationList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            onClick={openCreateOrganizationForm}
          >
            <Trans>Add New</Trans>
          </Button>
        </div>
      </div>

      <div className="flex justify-between gap-2 mt-4 font-normal max-sm:flex-wrap sm:items-center">
        <TableListSort
          options={[
            {
              id: 'merchants',
              label: i18n._('Merchants'),
            },
            {
              id: 'updatedAt',
              label: i18n._('Last Active'),
            },
          ]}
          sort={sort}
          setSort={setSort}
        />
        <div className="flex justify-between gap-2">
          <Button
            type="button"
            size="sm"
            onClick={() => setIsFiltersOpen(true)}
            className="sm:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden sm:block">
          <OrganizationFilters
            defaultFilters={{}}
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            name={name}
            status={status}
            i18n={i18n}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={isFiltersOpen}
          title={'Filters'}
          closeHandler={() => setIsFiltersOpen(false)}
          panelClassName="w-screen pointer-events-auto sm:max-w-sm"
        >
          <OrganizationFilters
            defaultFilters={{}}
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            name={name}
            status={status}
            i18n={i18n}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <div className="mt-4">
        <OrganizationsList
          organizationList={organizationList.data}
          refetch={organizationList.refetch}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          isLoading={organizationList.isLoading}
          openDetailsPanel={openOrganizationPanel}
        />
      </div>
      <CreateOrganization
        isOpen={isCreateOrganizationVisible}
        onCancel={closeCreateOrganizationForm}
        onSuccess={onCreateOrganizationSuccess}
      />
      <OrganizationViewPanel
        isOpen={isOrganizationDetailsOpen}
        onCancel={closeOrganizationPanel}
        row={organizationViewRow}
        i18n={i18n}
      />
    </div>
  );
};
