import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {MerchantChargebackStatsOutput} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  CurrencyCode,
  LocaleCode,
  formatAmountWithoutCurrency,
  formatPercentage,
} from '@zentact/common';
import {ExportToCsv} from '@zentact/ui-tailwind';

export const exportToCsvMerchantChargebackStats = (
  merchantList: MerchantChargebackStatsOutput,
  i18n: I18n,
  currency: CurrencyCode
) => {
  const exporter = new ExportToCsv({
    filename: `merchant-chargeback-stats-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!merchantList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    merchantList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account Id`]: row.merchantAccountId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store ID`]: row.storeId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store Reference ID`]: row.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.organizationName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account`]: row.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Payments Count`]: row.txCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Payments Volume`]: formatAmountWithoutCurrency(
        row.txVolume,
        i18n.locale as LocaleCode,
        currency
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Won Chargebacks Count`]: row.wonChargebacksCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Lost Chargebacks Count`]: row.lostChargebacksCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Total Chargebacks Count`]: row.totalChargebacksCount,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Lost Chargebacks Volume`]: formatAmountWithoutCurrency(
        row.lostChargebacksVolume,
        i18n.locale as LocaleCode,
        currency
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Chargeback Ratio`]: formatPercentage(
        row.txCount,
        row.totalChargebacksCount,
        i18n.locale as LocaleCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Currency`]: currency,
    }))
  );
};
