import {I18n} from '@lingui/core';
import {PublicBillingAddressMode} from '@zentact/common';

export const getLocalizedBillingAddressModeMap = (
  i18n: I18n
): Record<PublicBillingAddressMode, string> => ({
  [PublicBillingAddressMode.FULL]: i18n._('Full Address'),
  [PublicBillingAddressMode.PARTIAL]: i18n._('Postal Code Only'),
  [PublicBillingAddressMode.NONE]: i18n._('No Address'),
});
