import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {MerchantStoppedProcessingListOutput} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  CSV_EXPORT_FULL_DATE_FORMAT,
  CurrencyCode,
  LocaleCode,
  formatAmountWithoutCurrency,
} from '@zentact/common';
import {ExportToCsv} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';

export const exportToCsvStoppedProcessingMerchants = (
  merchantList: MerchantStoppedProcessingListOutput,
  i18n: I18n
) => {
  const exporter = new ExportToCsv({
    filename: `stopped-processing-merchants-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!merchantList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    merchantList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account Id`]: row.merchantAccountId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store ID`]: row.storeId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Store Reference ID`]: row.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Organization`]: row.organizationName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Account`]: row.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Days Inacvtive`]: Math.floor(
        DateTime.now().diff(DateTime.fromISO(row.lastPaymentDate), 'days').days
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Last Processing Date`]: DateTime.fromISO(row.lastPaymentDate as string).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`First Processing Date`]: DateTime.fromISO(row.firstPaymentDate as string).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Last Month Volume`]: formatAmountWithoutCurrency(
        row.lastMonthVolume,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Currency`]: row.currency,
    }))
  );
};
