import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {MerchantAccountDetailsOutput} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {formatLocaleDate} from '@zentact/common';
import {MerchantAccountEventType} from '@zentact/db';
import {Link, generatePath} from 'react-router-dom';

type Params = {
  i18n: I18n;
  merchantEvent: MerchantAccountDetailsOutput['merchantAccountEvents'][0];
  paymentDetailsPath: string;
  feeGroupListPath?: string;
  merchantsPath: string;
};

export const renderMerchantEventType = ({
  i18n,
  merchantEvent,
  paymentDetailsPath,
  feeGroupListPath,
  merchantsPath,
}: Params) => {
  if (merchantEvent.type === 'PRICE_GROUP_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>{i18n._('Fee Group updated')}</span>
        <span>
          {' '}
          {merchantEvent.oldFeeGroup.id && merchantEvent.oldFeeGroup.name && (
            <>
              {i18n._('from')}{' '}
              <Link
                className="text-primary-600"
                to={`${feeGroupListPath}/${merchantEvent.oldFeeGroup.id}`}
              >
                {merchantEvent.oldFeeGroup.name}
              </Link>{' '}
            </>
          )}
          {i18n._('to')}{' '}
          <Link
            className="text-primary-600"
            to={`${feeGroupListPath}/${merchantEvent.newFeeGroup.id}`}
          >
            {merchantEvent.newFeeGroup.name}
          </Link>{' '}
          {i18n._('by {email}', {email: merchantEvent.authUser.email})}
        </span>
      </div>
    );
  }
  if (merchantEvent.type === 'SETTLEMENT_DELAY_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>{i18n._('Settlement Delay updated')}</span>
        <span>
          {' '}
          {merchantEvent.authUser
            ? i18n._('from {previousValue} to {nextValue} by {email}', {
                previousValue: merchantEvent.previousValue,
                nextValue: merchantEvent.nextValue,
                email: merchantEvent.authUser.email,
              })
            : i18n._('from {previousValue} to {nextValue}', {
                previousValue: merchantEvent.previousValue,
                nextValue: merchantEvent.nextValue,
              })}
        </span>
      </div>
    );
  }
  if (merchantEvent.type === 'BANK_ACCOUNT_INFO_UPDATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>{i18n._('Bank Account Number updated')}</span>
        <span>
          {' '}
          {merchantEvent.previousbankAccountLast4 && merchantEvent.currentBankAccountLast4 && (
            <>
              {i18n._('from ')}
              {`*${merchantEvent.previousbankAccountLast4} `}
              {i18n._('to ')}
              {`*${merchantEvent.currentBankAccountLast4} `}
            </>
          )}
        </span>
      </div>
    );
  }
  if (merchantEvent.type === 'INVITED' && 'inviteeEmail' in merchantEvent) {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>{merchantEvent.inviteeEmail}</span> <span>{i18n._('Invited')}</span>
      </div>
    );
  }
  if (merchantEvent.type === 'ORGANIZATION_ASSIGNED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Assigned to organization')}{' '}
          <Link
            to={`${merchantsPath}?organizationId=${merchantEvent.organizationId}`}
            className="text-primary-600 hover:underline"
          >
            {merchantEvent.organizationName}
          </Link>{' '}
          {i18n._('by')} {merchantEvent.authUser.email}
        </span>
      </div>
    );
  }
  if (merchantEvent.type === 'FIRST_PAYMENT_PROCESSED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>{i18n._('First Payment Processed')}</span>{' '}
        <Link
          className="text-primary-600"
          to={generatePath(paymentDetailsPath, {
            pspReferenceId: merchantEvent.paymentPspReferenceId,
          })}
        >
          {merchantEvent.paymentPspReferenceId}
        </Link>
      </div>
    );
  }
  if (merchantEvent.type === 'CAPABILITIES_BLOCKED' && 'payoutsDisabledBy' in merchantEvent) {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._('Payouts have been disabled by {by}', {
            by: merchantEvent.payoutsDisabledBy,
          })}
        </span>
      </div>
    );
  }
  if (merchantEvent.type === 'ACTIVATED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {merchantEvent.authUser
            ? `${i18n._('Activated by')} ${merchantEvent.authUser.email}`
            : i18n._('Activated')}
        </span>
      </div>
    );
  }
  if (merchantEvent.type === 'DEACTIVATED' || merchantEvent.type === 'INACTIVE') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {'authUser' in merchantEvent && merchantEvent.authUser
            ? `${i18n._('Deactivated by')} ${merchantEvent.authUser.email}`
            : i18n._('Deactivated')}
        </span>
      </div>
    );
  }
  if (merchantEvent.type === 'ROLLING_RESERVE_APPLIED') {
    return (
      <div className="text-gray-700 md:col-span-1">
        <span>
          {i18n._(
            'Hold on reserves is applied to the merchant for {withHoldingPeriodInDays} days with {rollingReservePercentage}% held',
            {
              withHoldingPeriodInDays: merchantEvent.withHoldingPeriodInDays,
              rollingReservePercentage: merchantEvent.rollingReservePercentage,
            }
          )}
        </span>
      </div>
    );
  }
  const translations: {
    [Key in Exclude<
      MerchantAccountEventType,
      | 'PRICE_GROUP_UPDATED'
      | 'SETTLEMENT_DELAY_UPDATED'
      | 'BANK_ACCOUNT_INFO_UPDATED'
      | 'ORGANIZATION_ASSIGNED'
      | 'FIRST_PAYMENT_PROCESSED'
      | 'ACTIVATED'
      | 'DEACTIVATED'
      | 'INACTIVE'
      | 'ROLLING_RESERVE_APPLIED'
    >]: string;
  } = {
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    INITIATED: i18n._('Inititated'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    INVITED: i18n._('Invited'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    PENDING: i18n._('Onboarding Started'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    BOARDED: i18n._('Boarded'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    CAPABILITIES_BLOCKED: i18n._('Additional Verification Required'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    CLOSED: i18n._('Closed'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    SUSPENDED: i18n._('Suspended'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    IMPORTED: i18n._('Imported'),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    ROLLING_RESERVE_LIFTED: i18n._('Rolling Reserve Lifted'),
  };
  return <div className="text-gray-700 md:col-span-1">{translations[merchantEvent.type]}</div>;
};

type Props = {
  merchantAccount: MerchantAccountDetailsOutput;
  paymentDetailsPath: string;
  feeGroupListPath?: string;
  merchantsPath: string;
};

export const MerchantEventsList = ({
  merchantAccount,
  feeGroupListPath,
  paymentDetailsPath,
  merchantsPath,
}: Props) => {
  const {i18n} = useLingui();
  return (
    <ul>
      {merchantAccount.merchantAccountEvents.map((event, i) => (
        <li key={`event-${i}`} className="flex text-sm leading-6 ">
          <div className="flex flex-col items-center mr-5">
            <div className="mt-[2px] mb-[5px] h-[20px] w-[20px] rounded-full border-solid border-[2px] bg-white border-gray-200 shadow-sm" />
            {i < merchantAccount.merchantAccountEvents.length - 1 && (
              <div className="flex-1 w-[2px] bg-gray-200 mb-[3px]" />
            )}
          </div>
          <div className="grid w-full pb-5 md:grid-cols-[180px_minmax(0,_1fr)] gap-x-2">
            <div className="flex md:col-span-1">
              <div className="font-medium text-gray-900 whitespace-nowrap">
                {formatLocaleDate(event.createdAt)}
              </div>
            </div>
            {renderMerchantEventType({
              i18n,
              merchantEvent: event,
              feeGroupListPath,
              paymentDetailsPath,
              merchantsPath,
            })}
          </div>
        </li>
      ))}
    </ul>
  );
};
