import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {WrenchIcon} from '@heroicons/react/24/outline';
import {Trans, t} from '@lingui/macro';
import {
  ArrowIcon,
  BackwardLabelIcon,
  Breadcrumbs,
  CheckMarkIcon,
  ClockIcon,
  GraduateCapIcon,
  MoneyIcon,
  TwoPeopleIcon,
  Typography,
} from '@zentact/ui-tailwind';
import {useNavigate} from 'react-router-dom';

const getBreadCrumbs = () => [{name: t`Reports`, href: '#', current: true}];

export const TenantReports = () => {
  const navigate = useNavigate();
  const {tenant} = useStore();

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Reports</Trans>
        </Typography>
      </div>
      <div className="mt-4 overflow-hidden bg-gray-200 divide-y divide-gray-200 rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          className="relative p-6 bg-white rounded-tl-lg rounded-tr-lg cursor-pointer sm:rounded-tr-none group focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500"
          onClick={() => navigate(RoutePath.REPORTS_PROFITABILITY)}
        >
          <div>
            <span className="inline-flex p-3 text-teal-700 rounded-lg bg-teal-50 ring-4 ring-white">
              <ClockIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Trans>Transaction Profitability</Trans>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                Tracks revenue, fees, net income, and identifies trends to gauge financial
                performance.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            <ArrowIcon />
          </span>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          className="relative p-6 bg-white cursor-pointer sm:rounded-tr-lg group"
          onClick={() =>
            tenant?.features?.enableInterchangeReport?.showInterchangeReportPage &&
            navigate(RoutePath.REPORTS_INTERCHANGE)
          }
        >
          <div>
            <span className="inline-flex p-3 text-purple-700 rounded-lg bg-purple-50 ring-4 ring-white">
              <CheckMarkIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Trans>Interchange Report</Trans>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                Analyzes Level 2 & Level 3 data to optimize qualifying cards and manage interchange
                fees.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            {tenant?.features?.enableInterchangeReport?.showInterchangeReportPage ? (
              <ArrowIcon />
            ) : (
              <WrenchIcon className="w-6 h-6 shrink-0" />
            )}
          </span>
        </div>
        <div className="relative p-6 bg-white group focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500">
          <div>
            <span className="inline-flex p-3 rounded-lg bg-sky-50 text-sky-700 ring-4 ring-white">
              <TwoPeopleIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <Trans>Risk Report</Trans>
              </a>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                Monitors monthly volumes, chargebacks, and processing thresholds to mitigate risks
                and ensure compliance.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            <WrenchIcon className="w-6 h-6 shrink-0" />
          </span>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          className="relative p-6 bg-white rounded-tl-lg rounded-tr-lg cursor-pointer sm:rounded-tr-none group focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500"
          onClick={() => navigate(RoutePath.REPORTS_OTHER_REVENUE)}
        >
          <div>
            <span className="inline-flex p-3 text-yellow-700 rounded-lg bg-yellow-50 ring-4 ring-white">
              <MoneyIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Trans>Other Revenue Report</Trans>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                Provides a breakdown of non-transactional revenue, including monthly/yearly fees,
                terminal fees, chargebacks, and refund fees.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            <ArrowIcon />
          </span>
        </div>
        <div className="relative p-6 bg-white group focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500">
          <div>
            <span className="inline-flex p-3 rounded-lg bg-rose-50 text-rose-700 ring-4 ring-white">
              <BackwardLabelIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <Trans>Month to Month Performance Report</Trans>
              </a>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                Compares organizational and merchant performance on a monthly basis to evaluate
                growth and trends.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            <WrenchIcon className="w-6 h-6 shrink-0" />
          </span>
        </div>
        <div className="relative p-6 bg-white sm:rounded-bl-none group focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500">
          <div>
            <span className="inline-flex p-3 text-indigo-700 rounded-lg bg-indigo-50 ring-4 ring-white">
              <GraduateCapIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <Trans>Effective Merchant Discount Rate Report</Trans>
              </a>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                Calculates the effective discount rate to understand true processing costs and
                profitability.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            <WrenchIcon className="w-6 h-6 shrink-0" />
          </span>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          className="relative p-6 bg-white rounded-tl-lg rounded-tr-lg cursor-pointer sm:rounded-tr-none group focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500"
          onClick={() => navigate(RoutePath.RISK_MERCHANT_STOPPED_PROCESSING)}
        >
          <div>
            <span className="inline-flex p-3 text-teal-700 rounded-lg bg-teal-50 ring-4 ring-white">
              <ClockIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Trans>Stopped Processing Report</Trans>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                This report highlights accounts that have stopped processing payments for more than
                30 days. To minimize risk, these stale accounts should be reviewed and closed.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            <ArrowIcon />
          </span>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          className="relative p-6 bg-white rounded-tl-lg rounded-tr-lg cursor-pointer sm:rounded-tr-none group focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500"
          onClick={() => navigate(RoutePath.RISK_MERCHANT_CHARGREBACKS)}
        >
          <div>
            <span className="inline-flex p-3 text-yellow-700 rounded-lg bg-yellow-50 ring-4 ring-white">
              <CheckMarkIcon />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Trans>Chargeback Report</Trans>
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              <Trans>
                Identifies chargebacks by merchant to address disputes and minimize financial
                losses.
              </Trans>
            </p>
          </div>
          <span
            className="absolute pointer-events-none right-6 top-6 text-primary-300 group-hover:text-primary-600"
            aria-hidden="true"
          >
            <ArrowIcon />
          </span>
        </div>
      </div>
      <div />
    </div>
  );
};
