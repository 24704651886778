import {I18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {MerchantChargebackStatsOutputItem} from '@zentact/api/src/trpc/routers/reportingRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatPercentage,
} from '@zentact/common';
import {
  HighlightedText,
  TableSearchFilter,
  Tooltip,
  TruncatedText,
  getTableMeta,
  truncateText,
} from '@zentact/ui-tailwind';

export const chargebackRatioWarningThreshold = 1.8; // 1.8%

const columnsHelper = createColumnHelper<MerchantChargebackStatsOutputItem>();

type Props = {
  i18n: I18n;
  locale: LocaleCode;
  currency: CurrencyCode;
};
export const getMerchantChargebackStatsListColumns = ({locale, currency}: Props) => {
  return [
    columnsHelper.display({
      cell: props => <TruncatedText text={props.row.original.organizationName} />,
      header: () => <Trans>Organization</Trans>,
      id: 'organizationName',
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.businessName as string;
        const textContent = props.row.original.businessName || '-';

        return textContent.length > 20 ? (
          <Tooltip text={textContent}>
            <HighlightedText text={truncateText(textContent)} highlight={searchString} />
          </Tooltip>
        ) : (
          <HighlightedText text={textContent} highlight={searchString} />
        );
      },
      id: 'businessName',
      header: () => <Trans>Merchant Account</Trans>,
      meta: {
        filter: {
          filterId: 'businessName',
          renderFilter: ({...filterProps}) => <TableSearchFilter {...filterProps} />,
        },
        collapseAt: 'md',
      },
    }),
    columnsHelper.accessor('txCount', {
      cell: info => info.getValue(),
      header: () => <Trans>Payments</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        align: 'right',
      },
      size: TableColumnSize.XXS,
    }),
    columnsHelper.display({
      id: 'lostChargebacksCount',
      cell: info => (
        <div className="flex justify-end gap-x-1">
          <span className="text-green-500">{info.row.original.wonChargebacksCount}</span>/
          <span className="text-red-500">{info.row.original.lostChargebacksCount}</span>
        </div>
      ),
      header: () => <Trans>Chargebacks</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        align: 'right',
        headerTooltip: t`The column shows the total number of chargeback disputes categorized as won (resolved in
            favor of the merchant), pending (under review), and lost (resolved in favor of the
            customer).`,
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      id: 'chargebackRatio',
      cell: info => {
        const {txCount, totalChargebacksCount} = info.row.original;
        const percentage = formatPercentage(txCount, totalChargebacksCount, locale);
        if (
          txCount &&
          totalChargebacksCount &&
          (totalChargebacksCount / txCount) * 100 > chargebackRatioWarningThreshold
        ) {
          return <span className="text-red-500">{percentage}</span>;
        }
        return percentage;
      },
      header: () => <Trans>Chargeback Ratio</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        collapseAt: 'md',
        align: 'right',
        headerTooltip: t`This column represents the ratio of chargebacks to the total number of processed payments,
         indicating the proportion of chargebacks resolved against the merchant.`,
      },
      size: TableColumnSize.M,
    }),
    columnsHelper.accessor('txVolume', {
      cell: info => formatAmount(info.getValue(), locale, currency),
      header: () => <Trans>Payment Volume</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: 'lg',
        align: 'right',
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('lostChargebacksVolume', {
      cell: info => formatAmount(info.getValue(), locale, currency),
      header: () => <Trans>Lost Chargeback Volume</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: '2xl',
        align: 'right',
      },
      size: TableColumnSize.M,
    }),
  ];
};
