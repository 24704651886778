import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {Button, InputText, Label, ValidationError, useNotification} from '@zentact/ui-tailwind';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

const customAttributesNamesLimit = 10;

const getCustomAttributesNamesSchema = () =>
  z
    .object({
      customAttributesNames: z.array(
        z
          .string()
          .max(20, t`Custom attribute name must not exceed 20 characters`)
          .regex(
            /^$|^[a-zA-Z0-9_-]+$/,
            t`Custom attribute name can only contain letters, numbers, underscores and hyphens`
          )
      ),
    })
    .superRefine((data, ctx) => {
      const names = data.customAttributesNames;
      const uniqueNames = new Set<string>();

      for (let index = 0; index < names.length; index++) {
        const name = names[index];
        if (name) {
          if (uniqueNames.has(name)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['customAttributesNames', index],
              message: t`This attribute name is duplicated. Each attribute name must be unique.`,
            });
          } else {
            uniqueNames.add(name);
          }
        }
      }
    });

type CustomAttributesNamesForm = z.infer<ReturnType<typeof getCustomAttributesNamesSchema>>;

type Props = {
  customAttributesNamesDefaults: string[];
};

export const CheckoutCustomAttributesForm = ({customAttributesNamesDefaults}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const form = useForm<CustomAttributesNamesForm>({
    resolver: zodResolver(getCustomAttributesNamesSchema()),
    defaultValues: {
      customAttributesNames: customAttributesNamesDefaults,
    },
  });

  const {
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: {errors},
    register,
    reset,
  } = form;
  const trpcContext = trpc.useUtils();

  const customAttributesNames = watch('customAttributesNames') || [''];

  useEffect(() => {
    reset({
      customAttributesNames: customAttributesNamesDefaults.length
        ? customAttributesNamesDefaults
        : [''],
    });
  }, [customAttributesNamesDefaults]);

  const updateTenantCheckoutConfiguration = trpc.tenant.updateCheckoutConfiguration.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      showSuccessNotification(t`Checkout custom attributes have been updated`);
    },
    onError: () => {
      showErrorNotification(
        t`Failed to update checkout custom attributes`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback((data: CustomAttributesNamesForm) => {
    const attributes = data.customAttributesNames.filter(name => name);
    updateTenantCheckoutConfiguration.mutate({
      customAttributesNames: attributes,
    });
  }, []);

  const onAddNewCustomAttributeName = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (customAttributesNames.length < customAttributesNamesLimit) {
        setValue('customAttributesNames', [...customAttributesNames, '']);
      }
    },
    [customAttributesNames, setValue]
  );

  const onRemoveCustomAttributeName = useCallback(
    (index: number) => {
      const newCustomAttributesNames = [
        ...customAttributesNames.slice(0, index),
        ...customAttributesNames.slice(index + 1),
      ];
      setValue('customAttributesNames', newCustomAttributesNames);
      clearErrors(`customAttributesNames.${index}`);
    },
    [customAttributesNames, setValue]
  );

  return (
    <form>
      <div className="px-4 py-5 sm:px-6">
        {customAttributesNames.map((_name, index) => (
          <div className="flex flex-col w-full gap-6 md:w-1/2">
            <div key={`customAttributesNames-${index}`} className="flex items-center gap-2 mt-3">
              <div className="flex flex-col w-full md:flex-row">
                <Label className="w-full" text={t`Custom Attribute Name ${index + 1}`}>
                  <div className="grid grid-cols-4 gap-4">
                    <InputText
                      className="w-full col-span-3"
                      type="text"
                      {...register(`customAttributesNames.${index}` as const)}
                      hasError={Boolean(errors.customAttributesNames?.[index])}
                    />
                    {index > 0 && (
                      <Button
                        type="button"
                        onClick={() => onRemoveCustomAttributeName(index)}
                        className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
                      >
                        <Trans>Remove</Trans>
                      </Button>
                    )}
                  </div>
                  <ValidationError isVisible={Boolean(errors.customAttributesNames?.[index])}>
                    <Trans>{errors.customAttributesNames?.[index]?.message}</Trans>
                  </ValidationError>
                </Label>
              </div>
            </div>
          </div>
        ))}
        <div className="flex flex-col justify-between w-full mt-5 md:flex-row">
          <div className="flex mb-2 sm:w-auto">
            {customAttributesNames.length < customAttributesNamesLimit && (
              <Button
                size="lg"
                className="inline-block w-1/2 md:w-auto"
                onClick={e => onAddNewCustomAttributeName(e)}
              >
                {t`Add new`}
              </Button>
            )}
          </div>
          <div className="flex flex-col space-y-2 sm:mb-2 sm:flex-row sm:space-y-0 sm:space-x-2">
            <Button
              type="button"
              className="w-1/2 md:w-auto"
              size="lg"
              onClick={handleSubmit(onSubmit)}
              disabled={updateTenantCheckoutConfiguration.isLoading}
              isLoading={updateTenantCheckoutConfiguration.isLoading}
            >
              <Trans>Save</Trans>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
