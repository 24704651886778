import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  Breadcrumbs,
  EntityPicker,
  MerchantAccountsPicker,
  ResetTableFiltersButton,
  StatementList,
  TableSortValue,
  Typography,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useEffect, useMemo, useState} from 'react';
import {z} from 'zod';

const getBreadCrumbs = () => [
  {name: t`Customers`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  {name: t`Merchant Statements`, href: RoutePath.STATEMENTS, current: true},
];

const searchParamsSchema = z.object({
  selectedMerchantAccount: z.string().optional(),
  selectedOrganization: z.string().optional(),
});

export const Statements = () => {
  const {
    orgsWithBoardedMerchants: organizationList,
    activeAndInactiveMerchantAccounts,
    pspMerchantAccountName,
  } = useStore();
  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const selectedMerchantAccount = typedSearchParams?.selectedMerchantAccount;
  const selectedOrganization = typedSearchParams?.selectedOrganization;
  const selectedOrganizationName = useMemo(
    () =>
      selectedOrganization && organizationList.find(org => org.id === selectedOrganization)?.name,
    [selectedOrganization, organizationList]
  );

  const merchantAccounts = useMemo(() => {
    if (!selectedOrganization) {
      return activeAndInactiveMerchantAccounts;
    }
    return activeAndInactiveMerchantAccounts.filter(
      merchant => merchant.organizationId === selectedOrganization
    );
  }, [activeAndInactiveMerchantAccounts]);

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'date', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 24});

  const paymentFilters = useMemo(() => {
    return {
      where: {
        ...(selectedOrganization && {organizationId: selectedOrganization}),
        ...(selectedMerchantAccount && {merchantAccountId: selectedMerchantAccount}),
        ...(pspMerchantAccountName && {merchantAccount: {pspMerchantAccountName}}),
      },
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    };
  }, [selectedOrganization, sort, selectedMerchantAccount, pspMerchantAccountName]);

  const statementList = trpc.statement.statementList.useQuery(
    {
      ...paymentFilters,
      ...pagination,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );

  // When filter or sorting changes we need to reset pagination
  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [paymentFilters]);

  useEffect(() => {
    if (
      selectedOrganization &&
      !merchantAccounts.find(merchant => merchant.id === selectedMerchantAccount)
    ) {
      setTypedSearchParams({selectedMerchantAccount: undefined});
    }
  }, [setTypedSearchParams, selectedOrganization, merchantAccounts, selectedMerchantAccount]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="py-2">
        <Typography variant="header-page" className="flex">
          <Trans>Merchant Statements</Trans>
          {selectedOrganizationName && ` - ${selectedOrganizationName}`}
        </Typography>
      </div>
      <div className="flex justify-end gap-2 pt-4 max-2xl:flex-wrap 2xl:items-center">
        <div className="flex gap-2 font-normal max-2xl:flex-wrap 2xl:items-center">
          <div className="max-sm:w-full">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          {!!organizationList?.length && (
            <div className="font-normal shrink-0 max-sm:w-full">
              <EntityPicker
                selected={selectedOrganization}
                onChange={value => setTypedSearchParams({selectedOrganization: value})}
                options={organizationList}
              />
            </div>
          )}
          {!!merchantAccounts?.length && (
            <div className="font-normal shrink-0 w-60">
              <MerchantAccountsPicker
                selectedMerchantAccount={selectedMerchantAccount || undefined}
                onSelectMerchantAccount={value =>
                  setTypedSearchParams({selectedMerchantAccount: value})
                }
                merchantAccountsOptions={
                  selectedOrganization
                    ? merchantAccounts.filter(
                        merchantAccount => merchantAccount.organizationId === selectedOrganization
                      )
                    : merchantAccounts
                }
                allLabel={t`All Merchant Accounts`}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 overflow-x-auto">
        <div className="mb-4 text-sm leading-6 text-gray-500">
          <Trans>
            You can view up to 7 years of statements. New statements will usually post on the first
            of the next month.
          </Trans>
        </div>
        <StatementList
          statementList={statementList.data}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          trpc={trpc}
          isLoading={
            statementList.isLoading || (statementList.isRefetching && statementList.isPreviousData)
          }
        />
      </div>
    </div>
  );
};
