import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {i18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {allIndustryGroups} from '@zentact/common';
import {
  AlertOverlay,
  Button,
  SlideOverWithBrandedHeader,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  AddIndustryCodeForm,
  ApiIndustryCodeFormData,
  addIndustryCodeFormSchema,
} from './add-industry-code-form';

type AddIndustryCodeProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

export const AddIndustryCode = ({isOpen, onSuccess, onCancel}: AddIndustryCodeProps) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [industryCodeKeyConfirmationOpen, setIndustryCodeKeyConfirmationOpen] = useState(false);

  const addIndustryCodeMutation = trpc.tenant.addIndustryCode.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`New Industry Code was added`);
      onSuccess();
    },
    onError: error => {
      onCancel();
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const form = useForm<ApiIndustryCodeFormData>({
    resolver: zodResolver(addIndustryCodeFormSchema()),
  });

  const {handleSubmit} = form;

  const onSubmit = useCallback(async (data: ApiIndustryCodeFormData) => {
    // if data.code doesn't exist in list
    if (!(data.code in allIndustryGroups)) {
      setIndustryCodeKeyConfirmationOpen(true);
      return;
    }
    await addIndustryCodeMutation.mutateAsync({
      industryCode: data.code,
    });
  }, []);

  const onCancelClick = useCallback((_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel();
    form.reset();
  }, []);

  return (
    <>
      <SlideOverWithBrandedHeader
        isOpen={isOpen}
        title={t`Add New Industry Code`}
        closeHandler={onCancelClick}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={handleSubmit(onSubmit)}
              >
                <Trans>Add</Trans>
              </Button>
            </div>
            <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
              <Trans>Close</Trans>
            </Button>
          </footer>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddIndustryCodeForm form={form} />
        </form>
      </SlideOverWithBrandedHeader>
      <AlertOverlay
        open={industryCodeKeyConfirmationOpen}
        setOpen={setIndustryCodeKeyConfirmationOpen}
        mode="warning"
        localeText={{
          title: i18n._('Unknown Code'),
          description: i18n._(
            'This industry code is not configured, contact Zentact support to get it added.'
          ),
          okay: i18n._('Cancel'),
        }}
      />
    </>
  );
};
