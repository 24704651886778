import {trpc} from '@/api/trpcClient';
import {useLocale} from '@/hooks';
import {useStore} from '@/store';
import {Plural, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ContextTenantCheckoutConfiguration} from '@zentact/api/src/trpc/routers/tenantRouter';
import {formatAmount} from '@zentact/common';
import {
  Button,
  PortalPaymentsDisableToggle,
  Typography,
  getLocalizedBillingAddressModeMap,
  useToggleWithData,
} from '@zentact/ui-tailwind';
import {EditPaymentConfiguration} from './edit-payment-configuration';

type Props = {
  checkoutConfiguration: ContextTenantCheckoutConfiguration;
  disablePortalPayments: boolean;
};

export const CheckoutDefaults = ({checkoutConfiguration, disablePortalPayments}: Props) => {
  const {locale} = useLocale();
  const {currency} = useStore();
  const {i18n} = useLingui();
  const {minAmount, maxAmount, expirationMinutes, billingAddressMode} = checkoutConfiguration;

  const {
    data: editPaymentConfiguration,
    on: openPaymentConfiguration,
    off: closePaymentConfiguration,
  } = useToggleWithData<ContextTenantCheckoutConfiguration>();

  return (
    <>
      <div className="pb-5 mt-4 bg-white shadow rounded-xl">
        <div className="flex items-center justify-between px-4 py-5 mb-5 border-b border-gray-200 sm:px-6">
          <div>
            <Typography variant="header-section">
              <Trans>Payment Configuration</Trans>
            </Typography>
            <div className="mt-3 text-gray-700">
              <Trans>These settings apply to Virtual Terminal (if enabled) and the API.</Trans>
            </div>
          </div>
          <Button
            variant="primary"
            size="lg"
            className="w-fit"
            onClick={() => openPaymentConfiguration(checkoutConfiguration)}
          >
            <Trans>Edit</Trans>
          </Button>
        </div>
        <div className="grid gap-4 px-4 sm:px-6">
          <div className="leading-6 border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="pb-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[300px] sm:mr-6">
                  <Trans>Minimum Transaction Amount</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {formatAmount(minAmount, locale, currency)}
                </dd>
              </div>
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[300px] sm:mr-6">
                  <Trans>Maximum Transaction Amount</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {formatAmount(maxAmount, locale, currency)}
                </dd>
              </div>
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[300px] sm:mr-6">
                  <Trans>Link Expiration</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  <Trans>
                    {expirationMinutes}{' '}
                    <Plural value={expirationMinutes} one="Minute" other="Minutes" />
                  </Trans>
                </dd>
              </div>
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[300px] sm:mr-6">
                  <Trans>Billing Address</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {getLocalizedBillingAddressModeMap(i18n)[billingAddressMode]}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Virtual Terminal Payments</Trans>
          </Typography>
          <div className="mt-3 text-gray-700">
            <Trans>Allow Merchants to take Payments from the Virtual Terminal.</Trans>
          </div>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <PortalPaymentsDisableToggle trpc={trpc} disablePortalPayments={disablePortalPayments} />
        </div>
      </div>
      {editPaymentConfiguration && (
        <EditPaymentConfiguration
          paymentConfiguration={editPaymentConfiguration}
          onClose={closePaymentConfiguration}
          isOpen={!!openPaymentConfiguration}
        />
      )}
    </>
  );
};
