import {trpc} from '@/api/trpcClient';
import {useLocale} from '@/hooks';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {I18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ContextTenantCheckoutConfiguration} from '@zentact/api/src/trpc/routers/tenantRouter';
import {PublicBillingAddressMode, fromMinorUnits, toMinorUnits} from '@zentact/common';
import {
  Button,
  InputSelect,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';

const getPaymentConfigurationSchema = () =>
  z
    .object({
      minAmount: z.coerce
        .number({
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          invalid_type_error: t`Amount must be a number`,
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          required_error: t`Amount is required`,
        })
        .multipleOf(0.01, t`Amount must be a multiple of 0.01`)
        .positive(t`Amount must be greater than 0`),
      maxAmount: z.coerce
        .number({
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          invalid_type_error: t`Amount must be a number`,
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          required_error: t`Amount is required`,
        })
        .multipleOf(0.01, t`Amount must be a multiple of 0.01`)
        .positive(t`Amount must be greater than 0`)
        .max(1000000, t`Amount can't be greater than 1 million`),
      expirationMinutes: z.coerce
        .number({
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          invalid_type_error: t`Expiration Minutes must be a number`,
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          required_error: t`Expiration Minutes is required`,
        })
        .int(t`Expiration Minutes must be an integer`)
        .positive(t`Expiration Minutes must be greater than 0`)
        .max(518400, t`Expiration Minutes can't be greater than 1 year`),
      billingAddressMode: z.enum(
        [
          PublicBillingAddressMode.FULL,
          PublicBillingAddressMode.PARTIAL,
          PublicBillingAddressMode.NONE,
        ],
        {
          // biome-ignore lint/style/useNamingConvention: Zod Convention
          required_error: t`Billing Address is required`,
        }
      ),
    })
    .superRefine((data, ctx) => {
      if (data.maxAmount <= data.minAmount) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['maxAmount'],
          message: t`Max Amount must be greater than Min Amount`,
        });
      }
    });

type PaymentConfigurationData = z.infer<ReturnType<typeof getPaymentConfigurationSchema>>;

export const getSelectBillingAddressModeOptions = (i18n: I18n) => [
  {
    id: PublicBillingAddressMode.FULL,
    label: i18n._('Full Address (Maximum Protection)'),
  },
  {
    id: PublicBillingAddressMode.PARTIAL,
    label: i18n._('Postal Code Only (Min. for interchange discount)'),
  },
  {
    id: PublicBillingAddressMode.NONE,
    label: i18n._('No Address (Not recommended, fraud risk)'),
  },
];

export type EditPaymentConfigurationProps = {
  paymentConfiguration: ContextTenantCheckoutConfiguration;
  onClose: () => void;
  isOpen: boolean;
};

export const EditPaymentConfiguration = ({
  paymentConfiguration,
  onClose,
  isOpen,
}: EditPaymentConfigurationProps) => {
  const {currency} = useStore();
  const {i18n} = useLingui();
  const {locale} = useLocale();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const trpcContext = trpc.useUtils();

  const editPaymentConfigurationForm = useForm<PaymentConfigurationData>({
    resolver: zodResolver(getPaymentConfigurationSchema()),
    defaultValues: {
      minAmount: fromMinorUnits(paymentConfiguration.minAmount, currency),
      maxAmount: fromMinorUnits(paymentConfiguration.maxAmount, currency),
      expirationMinutes: paymentConfiguration.expirationMinutes,
      billingAddressMode: paymentConfiguration.billingAddressMode as PublicBillingAddressMode,
    },
  });
  const {
    handleSubmit,
    formState: {errors},
    register,
    control,
  } = editPaymentConfigurationForm;

  const updatePaymentConfiguration = trpc.tenant.updateCheckoutConfiguration.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      onClose();
      showSuccessNotification(
        t`Payment configuration has been updated`,
        t`You have successfully updated payment configuration.`
      );
    },
    onError: () => {
      showErrorNotification(
        t`Failed to update payment configuration`,
        t`Something went wrong. Plaese try again later.`
      );
    },
  });

  const onUpdateMerchantSettlementDelaySubmit = useCallback(
    (data: PaymentConfigurationData) => {
      updatePaymentConfiguration.mutate({
        minAmount: toMinorUnits(data.minAmount, currency),
        maxAmount: toMinorUnits(data.maxAmount, currency),
        expirationMinutes: data.expirationMinutes,
        billingAddressMode: data.billingAddressMode,
      });
    },
    [paymentConfiguration, currency]
  );

  const currencySymbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Payment Configuration')}
      text={i18n._('These settings apply to Virtual Terminal (if enabled) and the API.')}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSettlementDelaySubmit)}
              isLoading={updatePaymentConfiguration.isLoading}
            >
              <Trans>Save Changes</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSettlementDelaySubmit)}>
        <div>
          <div className="flex flex-col gap-3">
            <Label text={t`Transaction Amount`}>
              <p className="text-sm leading-5 text-gray-500">
                {t`These minimum and maximum values apply to the virtual terminal and API transactions.`}
              </p>
              <Label text={t`Minimum Transaction Amount`}>
                <InputText
                  addonBefore={currencySymbol}
                  {...register('minAmount')}
                  hasError={Boolean(errors.minAmount)}
                />
                <ValidationError isVisible={Boolean(errors.minAmount)}>
                  {errors.minAmount?.message}
                </ValidationError>
              </Label>
              <Label text={t`Maximum Transaction Amount`}>
                <InputText
                  addonBefore={currencySymbol}
                  {...register('maxAmount')}
                  hasError={Boolean(errors.maxAmount)}
                />
                <ValidationError isVisible={Boolean(errors.maxAmount)}>
                  {errors.maxAmount?.message}
                </ValidationError>
              </Label>
            </Label>
            <Label text={t`Link Expiration`}>
              <p className="text-sm leading-5 text-gray-500">
                {t`This sets how many minutes the payment link will remain active after it has been generated. After it expires, a new link will need to be generated to take a payment. This is only for transactions generated from the virtual terminal in Zentact's merchant portal or via Zentact's API.`}
              </p>
              <InputText
                {...register('expirationMinutes')}
                hasError={Boolean(errors.expirationMinutes)}
              />
              <ValidationError isVisible={Boolean(errors.expirationMinutes)}>
                {errors.expirationMinutes?.message}
              </ValidationError>
            </Label>
            <Label text={t`Billing Address`}>
              <p className="text-sm leading-5 text-gray-500">
                {t`Choose how much of the billing address a customer is required to input to complete the transaction when making payments via Zenctact's virtual terminal or Zentact API. Requiring a full address is less prone to fraud, but can cause more abandoned checkouts due to friction.`}
              </p>
            </Label>
            <Controller
              control={control}
              name="billingAddressMode"
              render={({field}) => (
                <>
                  <InputSelect
                    value={field.value}
                    options={getSelectBillingAddressModeOptions(i18n)}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    placeholder={i18n._('Billing Address')}
                  />
                  <ValidationError isVisible={Boolean(errors.billingAddressMode)}>
                    {errors.billingAddressMode?.message}
                  </ValidationError>
                </>
              )}
            />
          </div>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
