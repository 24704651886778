import {Controller, useFormContext} from 'react-hook-form';
import {ValidationError} from '../validation-error';

export const SALES_CHANNEL_OPTIONS = {
  pos: {
    label: 'POS (Point of Sale)',
  },
  eCommerce: {
    label: 'eCommerce',
  },
  posMoto: {
    label: 'POSMoto (Mail Order/Telephone Order)',
  },
  ecomMoto: {
    label: 'ecomMoto',
  },
  payByLink: {
    label: 'Pay by Link',
  },
};

export const SalesChannels = ({
  options,
  defaultValues,
}: {
  options: string[];
  defaultValues: string[];
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext<{
    salesChannels: ('pos' | 'posMoto' | 'eCommerce' | 'ecomMoto' | 'payByLink')[];
  }>();

  return (
    <fieldset>
      <legend className="sr-only">Sales Channels</legend>
      <div className="space-y-5">
        {options.map(optionKey => {
          const option = SALES_CHANNEL_OPTIONS[optionKey as keyof typeof SALES_CHANNEL_OPTIONS];
          return (
            <div key={optionKey} className="relative flex items-start">
              <div className="flex items-center h-6">
                <Controller
                  name="salesChannels"
                  control={control}
                  defaultValue={defaultValues as (keyof typeof SALES_CHANNEL_OPTIONS)[]}
                  render={({field}) => {
                    const {value = [], onChange} = field;
                    const isChecked = value.includes(
                      optionKey as keyof typeof SALES_CHANNEL_OPTIONS
                    );

                    return (
                      <input
                        type="checkbox"
                        id={optionKey}
                        checked={isChecked}
                        onChange={e => {
                          if (e.target.checked) {
                            onChange([...value, optionKey]);
                          } else {
                            onChange(value.filter((v: string) => v !== optionKey));
                          }
                        }}
                        className="w-4 h-4 border-gray-300 rounded text-primary-600 focus:ring-primary-600"
                      />
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={optionKey} className="font-medium text-gray-900">
                  {option.label}
                </label>
              </div>
            </div>
          );
        })}
        <ValidationError isVisible={Boolean(errors.salesChannels)}>
          {errors.salesChannels?.message}
        </ValidationError>
      </div>
    </fieldset>
  );
};
