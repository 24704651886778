import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {
  MerchantReviewPanel,
  MerchantReviewPanelProps,
} from '@/pages/customers/merchants-list/merchant-review-panel';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {MerchantAccountPublicStatus, awaitingApprovalSearchParamSchema} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  EntityPicker,
  TableSortValue,
  Typography,
  useNotification,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {exportToCsvMerchantsAwaitingApproval} from './csv-export';
import {MerchantsList} from './merchants-list';

const breadcrumbs = () => [
  {name: t`Customers`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  {name: t`Awaiting Approval`, href: '#', current: true},
];

export const AwaitingApproval = () => {
  const {organizationList, pspMerchantAccountName} = useStore();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {i18n} = useLingui();

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    awaitingApprovalSearchParamSchema
  );

  const organizationId = typedSearchParams?.organizationId;
  const businessName = typedSearchParams?.businessName;

  const [sort, setSort] = useState<TableSortValue<string> | null>({
    columnId: 'kycCompletedAt',
    value: 'asc',
  });
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const awaitingApprovalMerchants = trpc.merchantAccount.getMerchantAccountsList.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        pspMerchantAccountName,
        status: [MerchantAccountPublicStatus.AWAITING_REVIEW],
        ...(organizationId && {organizationId}),
        ...(businessName && {businessName}),
      },
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      keepPreviousData: true,
    }
  );

  const totalItems = awaitingApprovalMerchants.data?.pagination.total ?? 0;
  const showPagination = totalItems > pagination.pageSize * 2;

  // When filter or sorting changes we need to reset pagination
  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const handleSelectOrganization = useCallback((organizationId?: string) => {
    setTypedSearchParams({organizationId});
  }, []);

  const {
    data: merchantReviewRow,
    on: openMerchantReviewPanel,
    off: closeMerchantReviewPanel,
  } = useToggleWithData<MerchantReviewPanelProps['merchantAccountRow']>();

  const trpcContext = trpc.useUtils();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    if (!pspMerchantAccountName) {
      console.error('pspMerchantAccountName is not defined');
      return;
    }

    setCsvLoading(true);
    try {
      const fullMerchantsWaitingApprovalList =
        await trpcContext.merchantAccount.getMerchantAccountsList.fetch({
          ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
          where: {
            pspMerchantAccountName,
            status: [MerchantAccountPublicStatus.AWAITING_REVIEW],
            ...(organizationId && {organizationId}),
            ...(businessName && {businessName: businessName}),
          },
        });
      exportToCsvMerchantsAwaitingApproval(fullMerchantsWaitingApprovalList, i18n);
      showSuccessNotification(t`Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [
    setCsvLoading,
    trpcContext,
    sort,
    organizationId,
    businessName,
    exportToCsvMerchantsAwaitingApproval,
  ]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Merchants Awaiting Approval</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          <div className="font-normal shrink-0 max-sm:w-full">
            <EntityPicker
              selected={organizationId || undefined}
              onChange={handleSelectOrganization}
              options={organizationList}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={
              !awaitingApprovalMerchants.data || awaitingApprovalMerchants.data.rows.length === 0
            }
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4">
        {/* @ts-ignore */}
        <MerchantsList
          merchantList={awaitingApprovalMerchants.data}
          refetch={awaitingApprovalMerchants.refetch}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={showPagination ? pagination : undefined}
          onPaginationChange={setPagination}
          mode="onlyPending"
          openReviewPanel={openMerchantReviewPanel}
          isLoading={awaitingApprovalMerchants.isLoading}
        />
      </div>
      <MerchantReviewPanel
        isOpen={!!merchantReviewRow}
        onCancel={closeMerchantReviewPanel}
        merchantAccountRow={merchantReviewRow}
        onSubmit={() => awaitingApprovalMerchants.refetch()}
      />
    </div>
  );
};
