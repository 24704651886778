import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {
  AlertOverlayWithConfirmation,
  BaseTheme,
  BrandColors,
  Breadcrumbs,
  InputText,
  Typography,
  UploadBrandLogo,
  getPalleteFromPrimaryColor,
  uploadFileToCloudStorage,
  useNotification,
} from '@zentact/ui-tailwind';
import {nanoid} from 'nanoid';
import {useCallback, useState} from 'react';

export enum LogoType {
  SQUARE = 'square',
  HORIZONTAL = 'horizontal',
}

const LOGO_DISPLAY_SETTINGS = {
  horizontal: '?max-h=80&max-w=300&fit=fill',
  square: '?max-h=80&max-w=80&fit=crop',
};

const getBreadCrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Brand`, href: RoutePath.BRAND, current: true},
];

export const Brand = () => {
  const [deleteConfirm, setDeleteConfirm] = useState<LogoType>();
  const [isLoadingHorizontal, setIsLoadingHorizontal] = useState(false);
  const [isLoadingSquare, setIsLoadingSquare] = useState(false);
  const [isConfirmationResetOpen, setIsConfirmationResetOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const trpcContext = trpc.useUtils();
  const {data: tenantBrandData} = trpc.tenant.getContextTenant.useQuery();

  const {
    logoUrl: horizontalUrl,
    logoFileName: horizontalLogoFileName,
    smallLogoUrl: squareUrl,
    smallLogoFileName: squareLogoFileName,
    primaryColorHex: primaryDefaultColor = BaseTheme.primary,
    supportEmail: tenantSupportEmail,
    fromEmail: tenantFromEmail,
  } = tenantBrandData?.brandConfiguration || {};

  const portalUrl = tenantBrandData?.portalUrl;

  const updateBranding = trpc.tenant.updateBranding.useMutation({
    onSuccess: updatedTenant => {
      setIsConfirmationResetOpen(false);
      setDeleteConfirm(undefined);
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
    },
    onError: err => {
      console.error(err);
    },
  });

  const onSelectLogo = useCallback(
    async (file: File, type: LogoType) => {
      if (type === LogoType.SQUARE) {
        setIsLoadingSquare(true);
      } else {
        setIsLoadingHorizontal(true);
      }
      // file with the same name can already be uploaded and cached on the UI. Adds a random part to avoid uploading same name file.
      const fileName = `${nanoid()}-${file.name}`;

      try {
        const {signedUrl} = await trpcContext.tenant.generateLogoUploadLink.fetch({
          fileName,
        });

        await uploadFileToCloudStorage(signedUrl, file);

        const updatedOrganization = await trpcContext.client.tenant.updateBranding.mutate(
          type === LogoType.SQUARE
            ? {
                smallLogoFileName: fileName,
              }
            : {
                logoFileName: fileName,
              }
        );
        trpcContext.tenant.getContextTenant.setData(undefined, updatedOrganization);
        showSuccessNotification(t`New logo was successfully uploaded`);
      } catch (e) {
        showErrorNotification(t`Failed to upload a logo`);
        console.error(e);
      }
      if (type === LogoType.SQUARE) {
        setIsLoadingSquare(false);
      } else {
        setIsLoadingHorizontal(false);
      }
    },
    [showErrorNotification, showSuccessNotification, setIsLoadingSquare, setIsLoadingHorizontal]
  );

  const onUpdateBrandColors = (color: string) => {
    const primary = getPalleteFromPrimaryColor(color);
    updateBranding.mutate({
      primaryColorHex: color,
      auth0OrganizationBrandColors: {
        primaryColor: primary['900'],
        backgroundColor: primary['200'],
      },
    });
  };

  const onDeleteLogo = (type?: LogoType) => {
    if (type === LogoType.HORIZONTAL) {
      updateBranding.mutate({
        logoFileName: null,
      });
    }
    if (type === LogoType.SQUARE) {
      updateBranding.mutate({
        smallLogoFileName: null,
      });
    }
  };

  const onDeleteConfirm = () => {
    if (deleteConfirm) {
      onDeleteLogo(deleteConfirm);
    }
  };
  const onResetColors = () => {
    setIsConfirmationResetOpen(true);
  };
  const onResetColorConfirm = () => {
    updateBranding.mutate({
      primaryColorHex: BaseTheme.primary,
    });
  };

  return (
    <div>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        <Trans>Brand</Trans>
      </Typography>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Email Configuration</Trans>
          </Typography>
          <div className="mt-3 mb-1 text-gray-500">
            <Trans>From Email</Trans>
          </div>
          <div className="relative w-full md:w-1/3 md:pr-2">
            <InputText
              readOnly={true}
              value={tenantFromEmail}
              disabled={true}
              className="read-only:bg-gray-100"
            />
          </div>
          <div className="mt-3 mb-1 text-gray-500">
            <Trans>Support Email</Trans>
          </div>
          <div className="relative w-full md:w-1/3 md:pr-2">
            <InputText
              readOnly={true}
              value={tenantSupportEmail}
              disabled={true}
              className="read-only:bg-gray-100"
            />
          </div>
        </div>
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Tenant Portal</Trans>
          </Typography>
          <div className="mt-3 mb-1 text-gray-500">
            <Trans>Custom domain name for merchant portal</Trans>
          </div>
          <div className="relative w-full md:w-1/3 md:pr-2">
            <InputText
              readOnly={true}
              value={portalUrl}
              disabled={true}
              className="read-only:bg-gray-100"
            />
          </div>
        </div>
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Logo</Trans>
          </Typography>
          <div className="mt-3 text-gray-700">
            <Trans>
              The Horizontal logo will appear on sign in, onboarding, email templates, and in your
              customer's administration portal. The square logo is used for the favicon.
            </Trans>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <UploadBrandLogo
            localeText={{
              title: t`Horizontal Logo`,
              delete: t`Reset to Default`,
              upload: t`Click to upload`,
              dragndrop: t`or drag and drop`,
            }}
            logoUrl={
              horizontalUrl ? `${horizontalUrl}${LOGO_DISPLAY_SETTINGS.horizontal}` : undefined
            }
            logoExist={!!horizontalLogoFileName}
            isLoading={isLoadingHorizontal}
            onDeleteLogo={() => setDeleteConfirm(LogoType.HORIZONTAL)}
            onSelectFile={f => onSelectLogo(f, LogoType.HORIZONTAL)}
            emptyLogoClassName="w-60 h-20"
          />
        </div>
        <div className="p-4 sm:px-6">
          <UploadBrandLogo
            isLoading={isLoadingSquare}
            localeText={{
              title: t`Square Logo`,
              delete: t`Reset to Default`,
              upload: t`Click to upload`,
              dragndrop: t`or drag and drop`,
            }}
            logoUrl={squareUrl ? `${squareUrl}${LOGO_DISPLAY_SETTINGS.square}` : undefined}
            logoExist={!!squareLogoFileName}
            onDeleteLogo={() => setDeleteConfirm(LogoType.SQUARE)}
            onSelectFile={f => onSelectLogo(f, LogoType.SQUARE)}
            emptyLogoClassName="w-20 h-20"
            maxImageDimensions={{width: 2048, height: 2048}}
          />
        </div>
        <AlertOverlayWithConfirmation
          setOpen={() => setDeleteConfirm(undefined)}
          handleAction={onDeleteConfirm}
          localeText={{
            title: t`Confirm reset`,
            description: t`Are you sure you want to reset this logo to default? Your changes will be lost.`,
            cancel: t`Cancel`,
            confirm: t`Confirm`,
          }}
          open={Boolean(deleteConfirm)}
        />
      </div>
      {tenantBrandData?.brandConfiguration.fontFamily && (
        <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
          <div className="px-4 py-5 sm:px-6">
            <Typography variant="header-section">
              <Trans>Font Family</Trans>
            </Typography>
            <div className="mt-3 mb-1 text-gray-500">
              <Trans>Custom font to show on merchant portal</Trans>
            </div>
            <div className="relative w-full md:w-1/3 md:pr-2">
              <InputText
                readOnly={true}
                value={tenantBrandData.brandConfiguration.fontFamily}
                disabled={true}
                className="read-only:bg-gray-100"
              />
            </div>
          </div>
        </div>
      )}
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Brand Color</Trans>
          </Typography>
          <div className="mt-3 text-gray-500">
            <Trans>
              Your brand color will be used to create a custom portal and emails for your merchants.
              For best result, pick a mid-range value. The other values are auto generated to create
              a cohesive feel.
            </Trans>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <Typography variant="header-subsection">
            <Trans>Base HEX Color Value</Trans>
          </Typography>
          <BrandColors
            primaryDefaultColor={primaryDefaultColor}
            onUpdateColors={onUpdateBrandColors}
            onResetColors={onResetColors}
            localeText={{
              apply: t`Apply Color Value`,
              reset: t`Reset to Default`,
              cancel: t`Cancel`,
            }}
          />
          <AlertOverlayWithConfirmation
            setOpen={setIsConfirmationResetOpen}
            handleAction={onResetColorConfirm}
            localeText={{
              title: t`Confirm reset to Default`,
              description: t`Are you sure you want to reset colors to Default? Your changes will be lost.`,
              cancel: t`Cancel`,
              confirm: t`Confirm`,
            }}
            open={isConfirmationResetOpen}
          />
        </div>
      </div>
    </div>
  );
};
